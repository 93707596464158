import { Link } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import remarkGfm from "remark-gfm"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"

const BlogPageTemplate = ({ pageContext: { blog } }) => {
  return (
    <Layout showContact={false}>
      <Seo
        title={blog.pageComponents.seoTitle || `${blog.title} - Doganbros.com`}
        description={
          blog.pageComponents.seoDescription || `${blog.description}`
        }
        keywords={blog.pageComponents.seoKeywords}
      />
      <section id="blog-template" className="section">
        <div className="container mb-6">
          <h3 className="title has-text-primary mb-6">{blog.title}</h3>
          <div className="level is-mobile">
            <div className="level-left">
              <div className="tags">
                {blog.blog_topics.data.map(
                  ({ attributes: { label, slug }, id }) => (
                    <Link
                      className="tag is-primary is-light"
                      key={id}
                      to={`/blog/categories/${slug}`}
                    >
                      {label}
                    </Link>
                  )
                )}
              </div>
            </div>
            <div className="level-right">
              <p>
                {new Date(blog.publishedAt).toLocaleDateString(undefined, {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
          </div>
          {/* <div className="level is-mobile">
            <div className="level-left">
              <span>
                <figure className="image is-32x32 is-square">
                  <SiteImage
                    image={blog.author.data.attributes.avatar}
                    alt="author avatar"
                    width={48}
                    height={48}
                  />
                </figure>
              </span>
              <span className="mx-3">{blog.author.data.attributes.name}</span>
            </div>
          </div> */}
          <div>
            <div className="my-5">
              <Slider dots>
                {blog.images.data.map((data, index) => (
                  <SiteImage
                    key={data.id}
                    image={{ data }}
                    alt={`blog image ${index + 1}`}
                    width={640}
                    height={480}
                  />
                ))}
              </Slider>
            </div>
            <div className="content block">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {blog.pageComponents.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPageTemplate
